<template>
  <mf-dialog v-model="dialog" max-width="1000" :title="'Detalhes ' + modalTitle(sale.type)" persistent>
    <v-row v-if="sale && sale.items">
      <v-col cols="12">
        <v-data-table class="mt-6 mx-4" :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }" :headers="headers" :items="sale.items">
          <template v-slot:[`item.options`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                <v-btn text color="primary" @click="copyId(item.sale_id)">Copiar ID</v-btn>
              </v-layout>
            </v-flex>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:[`item.ambev_incentive_months`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ sale.ambev_incentive ? sale.ambev_incentive.ambev_incentive_months : 0 }}
              </v-layout>
            </v-flex>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:[`item.ambev_incentive_text`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ sale.ambev_incentive ? sale.ambev_incentive.ambev_incentive_text : '-' }}
              </v-layout>
            </v-flex>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-else justify="center" class="mx-1">
      <v-col cols="auto">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <mf-button color="error" label="Sair" @click="close"></mf-button>
      </v-col>
    </v-row>
  </mf-dialog>
</template>

<script>
import { QUERY_GET_SALE_BY_ID } from '@/modules/retailers/graphql'
import { getSaleTypeByValue } from '@/constants/salesTypes'
import { parseCurrency } from '@/mixin/sales.js'
export default {
  mixins: [parseCurrency],
  props: {
    dialog: {
      type: Boolean,
      default: () => false
    },
    saleId: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    sale: null
  }),
  computed: {
    darkMode: {
      get() {
        return localStorage.getItem('dark_theme') === 'true'
      }
    },
    headers() {
      return [
        {
          text: 'Id da venda de origem',
          value: 'sale_id',
          align: 'center'
        },
        {
          text: 'Nome do produto',
          value: 'type'
        },
        {
          text: 'Quantidade',
          value: 'quantity'
        },
        {
          text: '',
          value: 'options',
          align: 'center'
        },
        {
          text: 'Ambev - Meses',
          value: 'ambev_incentive_months',
          align: 'center'
        },
        {
          text: 'Ambev - Texto',
          value: 'ambev_incentive_text',
          align: 'center'
        }
      ]
    },
    total() {
      let totalValue = 0
      this.sale.items.map(item => (totalValue += item?.total_value))
      return this.parseCurrencyDefault(totalValue)
    }
  },
  apollo: {
    sales: {
      query: QUERY_GET_SALE_BY_ID,
      fetchPolicy: 'network-only',
      variables() {
        return { sale_id: this.saleId }
      },
      update({ sale }) {
        this.sale = sale
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    }
  },
  methods: {
    modalTitle(saleType) {
      return getSaleTypeByValue(saleType)?.label ?? ''
    },
    close() {
      this.$emit('close')
      this.sale = null
    },
    mapIcon(type) {
      const mapIcons = {
        app: 'mdi-cellphone-text',
        cbm: 'mdi-archive',
        ecommerce: 'mdi-briefcase-plus',
        email: 'mdi-email-arrow-right-outline',
        sms: 'mdi-message-alert-outline',
        whatsapp: 'mdi-whatsapp'
      }
      return mapIcons[type]
    },
    copyId(id) {
      navigator.clipboard.writeText(id)
      this.$snackbar({ message: 'ID da venda de origem copiado', snackbarColor: '#2E7D32' })
    }
  }
}
</script>
